<template>
  <div>
    <v-container class="white mt-5 pa-8">
      <v-row>
        <v-col>
          <h2 class="text font-weight-regular">Statystyki użytkownika</h2>
        </v-col>
      </v-row>
      <v-row class="px-3"> <v-divider class="mb-6"></v-divider></v-row>
      <v-row>
        <v-col cols="8" sm="11">
          <date-range-filters
            @updateFromDate="updateFromDate"
            @updateToDate="updateToDate"
            :toDate="toDate"
            :fromDate="fromDate"
          ></date-range-filters>
        </v-col>
        <v-col cols="4" sm="1">
          <v-btn
            color="primary"
            elevation="0"
            :loading="loading"
            fab
            dark
            block
            @click="getUserStatistics"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
      </v-row>
      <v-row>
        <v-col>
          Wykonanych montażów: <strong>{{ statistics.totalNumberOfAssemblies || 0 }}</strong
          >, na łączną sumę:
          <strong>{{
            statistics.totalAmountOfAssemblies
              ? formatCurrency(statistics.totalAmountOfAssemblies)
              : '0 PLN'
          }}</strong
          >, o łącznej mocy: <strong>{{ statistics.totalPowerOfAssemblies || 0 }} kW</strong>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import DateRangeFilters from '../components/DateRangeFilters.vue';
import { formatCurrency } from '../mixins/formatCurrency';
import { formatDate } from '../mixins/formatDate';
import { mapGetters } from 'vuex';

export default {
  name: 'EmployeeStatistics',
  components: { DateRangeFilters },
  mixins: [formatCurrency, formatDate],
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      loading: false,
    };
  },
  methods: {
    async getUserStatistics() {
      try {
        this.loading = true;
        await this.$store.dispatch('user/getUserStatistics', {
          userId: this.userId,
          fromDate: this.formatDateISO(this.fromDate),
          toDate: this.formatDateISO(this.toDate ? this.toDate : new Date()),
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    updateFromDate(date) {
      this.fromDate = date;
    },
    updateToDate(date) {
      this.toDate = date;
    },
  },
  computed: {
    ...mapGetters('user', ['statistics']),
  },
};
</script>
