<template>
  <title-link-wrapper title="Wróć do listy użytkowników" :to="{ name: 'EmployeesList' }">
    <loading :loading="loading">
      <v-container class="white pa-8 mt-8">
        <v-row class="mb-6">
          <v-col>
            <h2 class="text font-weight-regular">Szczegóły użytkownika</h2>
          </v-col>
        </v-row>

        <v-form v-model="isFormValid" ref="form">
          <v-row>
            <v-col class="pt-1" cols="12" md="6">
              <h2 class="text mb-3">Imię*</h2>
              <v-text-field
                v-model="editingEmployee.firstName"
                :rules="validators.requiredRules"
                outlined
                solo
              ></v-text-field>
            </v-col>
            <v-col class="pt-1" cols="12" md="6">
              <h2 class="text mb-3">Nazwisko*</h2>
              <v-text-field
                v-model="editingEmployee.lastName"
                :rules="validators.requiredRules"
                outlined
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" cols="12" md="6">
              <h2 class="text mb-3">Numer telefonu*</h2>
              <v-text-field
                v-model="editingEmployee.phone"
                :rules="validators.phoneRules"
                outlined
                solo
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="6">
              <h2 class="text mb-3">E-mail*</h2>
              <v-text-field
                v-model="editingEmployee.email"
                :rules="validators.emailRules"
                outlined
                solo
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <h2 class="text mb-3">Rola*</h2>
              <v-select
                :items="roles"
                v-model="editingEmployee.role"
                :rules="validators.requiredSelectRules"
                clearable
                outlined
                solo
                class="right-input"
              ></v-select>
            </v-col>
          </v-row>
          <div v-if="!isEmployeeOfficeWorker">
            <v-row>
              <v-col class="pt-0" cols="12" md="6">
                <h2 class="text mb-3">Nazwa firmy*</h2>
                <v-text-field
                  v-model="editingEmployee.companyName"
                  :rules="validators.shortDescRequiredRules"
                  outlined
                  solo
                ></v-text-field>
              </v-col>
              <v-col class="pt-0" cols="12" md="6">
                <h2 class="text mb-3">NIP*</h2>
                <v-text-field
                  v-model="editingEmployee.NIP"
                  :rules="validators.nipRequiredRules"
                  outlined
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="px-3"><v-divider class="mb-6"></v-divider></v-row>
          </div>
        </v-form>
        <div v-if="!isEmployeeOfficeWorker">
          <v-row>
            <v-col>
              <h2 class="text mb-1">Umowa współpracy*</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="col-md-3">
              <outlined-button outlined class="text-none" :loading="isSelecting" :onClick="addFile">
                <v-icon left> mdi-plus-circle-outline </v-icon>
                {{ fileAdded ? 'Nadpisz plik' : 'Dodaj plik' }}
              </outlined-button>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                accept="application/pdf"
                @change="onFileChanged"
              />
            </v-col>
            <v-col v-if="editingEmployee.file.fullPath">
              <v-icon> mdi-file-document-outline </v-icon>
              <a class="pl-4" :href="editingEmployee.file.fullPath" target="_blank">
                <b>{{ addedFile.name }}</b>
              </a>
            </v-col>
          </v-row>
          <v-row class="mb-3">
            <v-col class="text-sm">
              Przesłana umowa powinna mieć formę skanu zapisaną w pliku .pdf o wadze max 2mb.
            </v-col>
          </v-row>
        </div>
        <v-row class="px-3"><v-divider class="mb-6"></v-divider></v-row>
        <v-row>
          <v-col cols="8" class="col-md-4">
            <outlined-button :onClick="editEmployee" :loading="loading" outlined
              >Zapisz</outlined-button
            >
          </v-col>
        </v-row>
      </v-container>
      <employee-statistics
        ref="employeeStatistics"
        v-if="!isEmployeeOfficeWorker"
        :userId="userId"
      ></employee-statistics>
    </loading>
  </title-link-wrapper>
</template>
<script>
import OutlinedButton from '../../components/buttons/OutlinedButton.vue';
import TitleLinkWrapper from '../../components/TitleLinkWrapper.vue';
import EmployeeStatistics from '../../components/EmployeeStatistics.vue';
import { validators, cooperationAgreement, checkFileSize } from '../../validators/Validators';
import { mapGetters } from 'vuex';
import Loading from '../../components/Loading.vue';
import { isEqual } from 'lodash';
import { objRoles } from '../../static/roles';

export default {
  name: 'EditEmployee',
  components: {
    OutlinedButton,
    TitleLinkWrapper,
    Loading,
    EmployeeStatistics,
  },
  data() {
    return {
      userId: '',
      isFormValid: false,
      isSelecting: false,
      roles: Object.values(objRoles),
      editingEmployee: {},
      validators,
      addedNewFile: false,
      addedFile: {},
      loading: false,
    };
  },
  methods: {
    async fetchEmployee() {
      this.loading = true;
      try {
        await this.$store.dispatch('user/getUserById', { userId: this.userId });
        this.editingEmployee = { ...this.employee };

        this.addedFile = this.employee.file;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    addFile() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      const file = e.target.files[0];

      if (file.name) {
        if (!cooperationAgreement(file.name)) {
          this.$toasted.global.error({
            message: 'Dodany plik ma niewłaściwy format.',
          });
          return;
        }
        if (!checkFileSize(file.size)) {
          this.$toasted.global.error({
            message: 'Dodany plik jest za duży.',
          });
          return;
        }
        this.addedFile = { ...file };
        this.$toasted.global.success({
          message: 'Plik został poprawnie dodany.',
        });
        this.addedNewFile = true;
      } else {
        this.$toasted.global.error({ message: 'Wybierz plik' });
      }
    },
    async editEmployee() {
      if (this.isFormValid) {
        try {
          this.$store.dispatch('user/updateUser', {
            userId: this.employee._id,
            user: this.editingEmployee,
          });
          if (this.addedNewFile) {
            await this.$store.dispatch('user/uploadFile', {
              userId: this.editingEmployee._id,
              type: this.addedFile.type,
              file: this.addedFile,
            });
          }

          this.$router.push({ name: 'EmployeesList' });
          this.$toasted.global.success({
            message: 'Użytkownik został poprawnie edytowany.',
          });
        } catch (error) {
          this.$toasted.global.error({
            message: 'Nie udało się edytować użytkownika. Spróbuj później.',
          });
          console.error(error);
        } finally {
          this.loading = false;
        }
      } else {
        this.$toasted.global.error({
          message: 'Formularz zawiera błędy.',
        });
      }
    },
  },
  computed: {
    ...mapGetters('user', ['employee', 'users']),
    isEmployeeOfficeWorker() {
      return isEqual(this.editingEmployee?.role, objRoles.OFFICE.value);
    },
    fileAdded() {
      return !!this.addedFile.size;
    },
  },
  async activated() {
    this.userId = this.$route.params.userId;
    await this.fetchEmployee();
    await this.$refs.employeeStatistics.getUserStatistics();
  },
};
</script>
<style lang="scss" scoped></style>
