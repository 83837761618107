export const formatCurrency = {
  methods: {
    formatCurrency(number) {
      const formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
      });
      const formattedNumber = formatter.format(number);
      return formattedNumber;
    },
  },
};
